<template>
  <div class="content">
    <div class="top">
      <a-input
        placeholder="输入大币种名称进行搜索"
        v-model="searchInpt"
        @pressEnter="search"
        @change="valueNull"
      />
      <button class="search" @click="search">搜索</button>
      <button class="add" size="large" style="width:90px" @click="openModal()">添加特征</button>

    </div>
    <div class="table-content">
      <a-table :columns="columns" :data-source="data" rowKey="id" @change="changePage" :pagination="pagination">
        <a slot="name" slot-scope="text">{{ text }}</a>
        <div slot="setup" slot-scope="row">
          <a @click="openFeatureModal(row)" style="margin-right: 5px">特征数据</a>

          <a @click="openModal(row)" style="margin-right: 5px">编辑</a>
          <a-popconfirm
            title="确定要删除此项么？"
            ok-text="确认"
            cancel-text="取消"
            @confirm="remove(row.id)"
          >
          <a style="margin-left: 5px">删除</a>
          </a-popconfirm>
        </div>
      </a-table>
    </div>

    <a-modal
      v-model="visible"
      :title="innerTitle"
      ok-text="确认"
      cancel-text="取消"
      @cancel="resetForms"
      @ok="hideModal"
    >
      <div class="modal">

        <p>大币种</p>
        <a-select allowClear style="width:150px;margin:5px;"  @search='searchBigData' ref='addse' v-model="coinKindId" :filter-option="untils.filterOption"  showSearch placeholder='请选择大币种' >
          <a-select-option
              v-for="item of currency_big_list"
              :key="item.id" :value='item.id'
          >{{item.coinKindName + (item.coinCategory ? `---${item.coinCategory}` : '' )}}</a-select-option>
        </a-select>
        <br/>
        <p>特征名称</p>
        <a-input placeholder="" v-model="featureTitle" />
        <p>筛选类型</p>
        <a-radio-group v-model="featureType" @change="featureTypeChange">
          <a-radio :value="1">
            小币种
          </a-radio>
          <a-radio :value="2">
            版本
          </a-radio>
        </a-radio-group>
        <p>级别</p>
        <a-radio-group v-model="level" @change="levelTypeChange">
          <a-radio :value="1">
            一级
          </a-radio>
          <a-radio :value="2">
            二级
          </a-radio>
        </a-radio-group>
        <br/>
        <p>排序</p>
        <a-input placeholder="" v-model="sort" />
      </div>
    </a-modal>


    <a-modal
      v-model="featureVisible"
      :title="innerTitle"
      ok-text="确认"
      cancel-text="取消"
      @cancel="resetForms"
      @ok="hideFeatureModal"
      :width = "700"
    >
      <div >
        <div style="display:flex" >
          <div>
            <p>特征币种</p>
            <a-select allowClear style="width:150px;margin:5px;"  @search='searchFeatureBigData' ref='addse' v-model="coinKindId" :filter-option="untils.filterOption" @change='changeBigSelect($event)' showSearch placeholder='请选择大币种' >
              <a-select-option v-for="item of feature_big_list" :key="item.coinKindId" :value='item.id'>{{item.coinKindName}}</a-select-option>
            </a-select>
          </div>
          <div>
            <p>特征选项</p>
            <a-select allowClear style="width:150px;margin:5px;"  ref='addse' v-model="featureId" :filter-option="untils.filterOption"  showSearch placeholder='请选择特征' >
              <a-select-option v-for="item of coin_feature_list" :key="item.id" :value='item.id'>{{item.featureTitle}}</a-select-option>
              </a-select>
          </div>
        </div> 
        
        <br/>
        <div v-for="(item,index) in featureData" :key="index" style="display:flex">
          <div>
            <p>展示名称</p>
            <a-input style="width:100px;" placeholder="" v-model="item.showName" />
          </div>

          <div>
            <p>搜索名称</p>
            <a-input style="width:100px;" placeholder="" v-model="item.searchName" />
          </div>
          <div>
            <p>排序</p>
            <a-input style="width:50px;" placeholder="" v-model="item.sort"/>
          </div>
          <div v-if="level > 1">
            <a-checkbox-group v-model="featureData[index].ids" :options='coin_feature_first_list'  @change="onChangeChecked($event,index)">
            </a-checkbox-group>
        </div>
          <a-button @click="addFeature" style="width:60px;">添加</a-button>
          <a-button @click="removeare(index)" v-if="index>0" style="width:60px;">删除</a-button>
        </div>

      </div>
    </a-modal>


  </div>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import {getBigCoinList} from "@/axios/apis"

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      editorOption: {
        placeholder: "编辑文章内容",
      },
      innerTitle: "123",
      visible: false,
      featureVisible: false,
      coinKindId: "",
      featureId: "",
      coinKindName: "",
      featureType: 1,
      level: 1,
      featureTitle: "",
      sort: "",
      openType: "",
      type: "",
      columns: [
        { title: "序号", dataIndex: "id", align: "center" },
        {
          title: "币种名称",
          align: "center",
          dataIndex: "coinKindName",
        },
        {
          title: "特征名称",
          align: "center",
          dataIndex: "featureTitle",
        },
        {title:'筛选类型',dataIndex:'featureType',key:'featureType',
            customRender:(text)=>{
                const inner = text
                if(inner == 1){
                    return <span style="color:green">小币种</span>
                }
                if(inner == 2){
                    return <span>版别</span>
                }
            }
        },
        {title:'级别',dataIndex:'level',key:'level',
            customRender:(text)=>{
                const inner = text
                if(inner == 1){
                    return <span style="color:green">一级</span>
                }
                if(inner == 2){
                    return <span style="color:red">二级</span>
                }
            }
        },
        {
          title: "排序",
          dataIndex: "sort",
          align: "center",
        },

        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "setup" },
        },
      ],
      data: [],
      currency_big_list:[],
      feature_big_list:[],
      coin_feature_list:[],
      coin_feature_first_list:[],
      firstCheckedList:[],
      pagination: {
        pageSize: 8,
        total: 0,
        current: 1,
        showTotal: (total) => `共${total}条`,
      },
      params: { pageNum: 1, pageSize: 8, coinKindName: "" },
      id: "",
      searchInpt: "",
      featureData:[{sort:"",list:[]}],
    };
  },
  methods: {
    getList(params) {
      this.axios("/dq_admin/coinFeature/list", {
        params: params,
      }).then((res) => {
        let list = res.data.records;
        for (let i = 0; i < list.length; i++) {
          if (list[i].type == 1) {
            list[i].type = "协议";
          }
          list[i].serialNumber = i + 1;
        }
        this.data = list;
        this.pagination.total = res.data.total;
      });
    },
    changePage(page) {
      const { current, pageSize } = page;

      this.pagination.current = current;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.getList(this.params);
    },
    async searchBigData(inner){
      const res = await getBigCoinList(inner)
      if (res.status !== '200') return;
      const {records} = res.data
      this.currency_big_list = records
      this.$forceUpdate()
    },

    searchFeatureBigData(inner){
        const coinValue = inner.value
        this.axios('/dq_admin/coinFeature/listAllCoin',{params:{coinKindName:coinValue}}).then(res=>{
            if(res.status == '200'){
                this.feature_big_list = res.data
                this.$forceUpdate()
            }
        })
    },

    firstLevelFeatureByCoin(){
        this.axios('/dq_admin/coinFeatureDetail/listFirstLevelValueByCoinId',{params:{coinKindId:this.coinKindId}}).then(res=>{
            if(res.status == '200'){
                const result = res.data.map(row=>{
                  let params = {value:row.id,label:row.showName};
                  return params
                })
                this.coin_feature_first_list =result;
                this.$forceUpdate()
            }
        })
    },

    
    changeBigSelect(coinId){
        this.coinKindId = coinId;
        this.axios('/dq_admin/coinFeature/listFeaturesByCoin',{params:{coinKindId:this.coinKindId}}).then(res=>{
            if(res.status == '200'){
                this.coin_feature_list = res.data
                this.$forceUpdate()
            }
        })
    },

    featureTypeChange(e) {
      this.featureType = e.target.value
    },
    levelTypeChange(e) {
      this.level = e.target.value
    },

    search(e) {
      if (this.searchInpt == "") {
        this.$message.warning("请输入标题再进行搜索");
      } else {
        this.params.page = 1;
        this.params.coinKindName = this.searchInpt;
        this.getList(this.params);
        this.pagination.current = 1;
      }
    },
    valueNull() {
      if (this.searchInpt == "") {
        this.params.coinKindName = this.searchInpt;
        this.getList(this.params);
        this.pagination.current = 1;
      }
    },
    openModal(row) {
      if (row) {
        this.visible = true;
        this.innerTitle = "编辑";
        this.id = row.id;
        this.axios("/dq_admin/coinFeature/getById", {
          params: { id: row.id },
        }).then((res) => {
          this.content = res.data.content;
        });
       
        this.featureTitle = row.featureTitle;
        this.sort = row.sort;
        this.coinKindId = row.coinKindId;
        this.coinKindName = row.coinKindName;
        this.featureType = row.featureType;
        this.level = row.level;
        this.openType = 1;
        this.id = row.id;
        if(this.coinKindId){
            this.currency_big_list = [{id:this.coinKindId,coinKindName:this.coinKindName}]
        }

      } else {
        this.openType = 2;
        this.sort = "";
        this.featureTitle = "";
        this.featureType = 1;
        this.coinKindId = "";
        this.coinKindName = "";
        this.visible = true;
        this.innerTitle = "新增特征";
      }
    },


    openFeatureModal(row) {
      if (row) {
        this.featureVisible = true;
        this.innerTitle = "特征数据";
        this.id = row.id;
        this.axios("/dq_admin/coinFeatureDetail/getByFeaturesId", {
          params: { featuresId: row.id},
        }).then((res) => {
        
          this.featureId = res.data.featuresId;
          this.coinKindId = res.data.coinKindId;
          this.coinKindName = res.data.coinKindName;
          this.featureTitle = res.data.featureTitle;
          if(res.data.list.length > 0){
            this.featureData = res.data.list;
          }
          this.firstLevelFeatureByCoin();
          if(this.coinKindId){
            this.feature_big_list = [{coinKindId:this.coinKindId,coinKindName:this.coinKindName}]
          }
          if(this.featureId){
              this.coin_feature_list = [{id:this.featureId,featureTitle:this.featureTitle}]
          }
        });
        this.openType = 1;
        this.level = row.level

      } else {
        this.featureId = "";
        this.featureTitle = "";
        this.coinKindId = "";
        this.coinKindName = "";
        this.featureVisible = true;
        this.innerTitle = "新增特征数据";
        this.searchFeatureBigData("");
      }
    },


    remove(id) {
      this.axios("/dq_admin/coinFeature/delById", {
        params: { id: id },
      }).then((res) => {
        this.getList(this.params);

        this.$message.success("删除成功");
      });
    },

    hideModal() {
      if (this.featureTitle == "") {
        this.$message.warning("请输入币种特征");
      } else if (this.coinKindId == "") {
        this.$message.warning("请选择币种");
      } else {
        if (this.openType == 1) {
          this.axios
            .post("/dq_admin/coinFeature/edit", {
              featureTitle: this.featureTitle,
              sort: this.sort,
              featureType: this.featureType,
              level:this.level,
              coinKindId: this.coinKindId,
              id:this.id,
            })
            .then((res) => {
              this.getList(this.params);
              this.visible = false;
              this.$message.success("修改成功");
            });
        } else {
          this.axios
            .post("/dq_admin/coinFeature/add", {
              featureTitle: this.featureTitle,
              sort: this.sort,
              featureType: this.featureType,
              level:this.level,
              coinKindId: this.coinKindId,
            })
            .then((res) => {
              this.getList(this.params);
              this.visible = false;
              this.$message.success("新增成功");
            });
        }
      }
    },

    hideFeatureModal() {
      if (this.coinKindId == "") {
        this.$message.warning("请输入币种特征");
      } else if (this.freatureId == "") {
        this.$message.warning("请选择特征选项");
      } else {
        this.axios
            .post("/dq_admin/coinFeatureDetail/add", {
              coinKindId: this.coinKindId,
              featuresId: this.featureId,
              list: this.featureData,
            })
            .then((res) => {
              this.getList(this.params);
              this.featureVisible = false;
              this.$message.success("新增成功");
            });
       }
    },

    resetForms() {
      this.currency_big_list = [];
      this.coin_feature_list = [];
      this.featureData = [{sort:""}];
    },
    addFeature(){
      this.featureData.push({})
    },
    removeare(index){
      this.featureData.splice(index,1)
    },
    onChangeChecked(event,index) {
      this.featureData[index].ids = event;
    },
  },
  mounted() {
    this.getList(this.params);
  },
};
</script>

<style lang="scss" scoped>

// .search-attr{display: flex;justify-content: space-between;padding-bottom:20px;}

.content{display: flex;flex-direction: column;
    .table-content{
        flex:1;
        overflow-y: auto;
    }
}

.top {
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    width: 65px;
    height: 28px;
    border: none;
    background: #1890ff;
    color: #ffffff;
    border-radius: 5px;
    margin-right: 25px;
    outline-style: none;
    cursor: pointer;
  }
}
.modal {
  input {
    margin-bottom: 5px;
  }
}
</style>
